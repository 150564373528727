import React from "react"
import { Helmet } from "react-helmet"
import NavigationBar from "../components/Navbar"
import loadable from "@loadable/component"
const Footer = loadable(() => import("../components/footer3"))

export default function PrivacyPolicy() {
  return (
    <div className="mb-6">
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>Privacy Policy</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="This Privacy Policy applies to our website. By using our website, you hereby consent to our Privacy Policy and agree to its terms."
        />
      </Helmet>
      <div className="sm:sticky top-0 z-50">
        <NavigationBar />
      </div>
      <div className="mt-6 container mx-auto px-5 md:px-7 lg:px-8 xl:px-10 pt-5 md:pt-8">
        <h1 className="tracking-wider text-2xl mb-4 xl:text-3xl text-gray-900 text-center">
          Privacy Policy
        </h1>

        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            This Privacy Policy applies to enjoyalgorithms.com. By using our
            website, you hereby consent to our Privacy Policy and agree to its
            terms. We recognize the importance of maintaining your privacy and
            appreciate your trust in us. This Policy describes how we treat user
            information we collect on https://enjoyalgorithms.com and other
            sources.
          </p>
          <p className="mb-4">
            enjoyalgorithms.com is a property of Code Algorithms Private
            Limited, an Indian company registered under the Companies Act, 2013
            having its registered office at: Vselek Coworking and Cowarehousing
            Private Limited, N- 161, Saira Tower, G.F, Gautam Nagar, Near Green
            Park Metro Station Gate no. 2, New Delhi, South Delhi DL-110049 IN.
          </p>
        </div>

        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Information we collect
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            While using our website, we may ask you to provide us with certain
            personal information that can be used to contact or identify you.
          </p>
          <p className="mb-4">
            Contact information: We might collect your name, email, mobile
            number, phone number, street, city, state, Pincode, country and IP
            address.
          </p>
          <p className="mb-4">
            Information you post: We collect the information you post in a
            public space on a third-party social media site belonging to
            enjoyalgorithms.com.
          </p>
          <p className="mb-4">
            Demographic information: We may collect demographic information
            about you, events you like, events you intend to participate in or
            any other information provided by you during the use of our website.
            We might collect this as a part of a survey also.
          </p>
          <p className="mb-4">
            Other information: If you use our website, we may collect
            information about your IP address and the browser you're using. We
            might look at what site you came from, the duration of time spent on
            our website, pages accessed or what site you visit when you leave
            us. We might also collect the type of mobile device you are using or
            the version of the operating system your computer or device is
            running.
          </p>
          <p className="mb-4">
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>
        </div>
        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Use of your personal information
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            We use the information we collect in various ways.
          </p>
          <p className="mb-4">
            We use the information to contact you: We might use the information
            you provide to contact you for confirmation of a purchase on our
            website or provide you with updates and other information relating
            to the website.
          </p>
          <p className="mb-4">
            We use the information to respond to your requests or questions.
          </p>
          <p className="mb-4">
            We use the information to improve our products and services: We
            might use your information to customize your experience with us.
            This could include displaying content based on your preferences.
          </p>
          <p className="mb-4">
            We use the information to look at site trends and customer
            interests. We may use your information to make our website and
            products better. We may combine information we get from you with
            information about you we get from third parties.
          </p>
          <p className="mb-4">
            We use the information for security purposes. We may use the
            information to protect our company, our customers, or our websites.
          </p>
          <p className="mb-4">
            We use the information for marketing and promotional purposes: We
            might send you information about special promotions or offers. We
            might also tell you about new features or products. These might be
            our own offers or products, or third-party offers or products we
            think you might find interesting.
          </p>
          <p className="mb-4">
            We use the information to send you transactional communications: We
            might send you emails or SMS about your account or service purchase.
            We use information as otherwise permitted by law.
          </p>
        </div>

        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Sharing of information with third-parties
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            We will share information with third parties who perform services on
            our behalf. Such third-party service providers fall into the
            following categories: payment processors, hosting service providers,
            analytics service providers, and marketing/email providers. Some
            vendors may be located outside of India.
          </p>
          <p className="mb-4">
            We may share information if we think we have to in order to comply
            with the law or to protect ourselves: We will share information to
            respond to a court order or subpoena. We may also share it if a
            government agency or investigatory body requests it. Or, we might
            also share information when we are investigating potential fraud.
          </p>
          <p className="mb-4">
            We may share your information for reasons not described in this
            policy: We will tell you before we do this.
          </p>
        </div>
        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Email Opt-Out
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            You can opt out of receiving our marketing emails. To stop receiving
            our promotional emails, please email contact@enjoyalgorithms.com. It
            may take about ten days to process your request. Even if you opt out
            of getting marketing messages, we will still be sending you
            transactional messages through email and SMS about your purchases.
          </p>
          <p className="mb-4">
            We may use your Information to contact you with newsletters. You may
            opt out of receiving any, or all, of these communications from us by
            following the unsubscribe link or instructions provided in any email
            we send or by contacting us.
          </p>
        </div>

        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Do Not Track Disclosure
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            We do not support Do Not Track ("DNT"). Do Not Track is a preference
            you can set in your web browser to inform websites that you do not
            want to be tracked. You can enable or disable Do Not Track by
            visiting the Preferences or Settings page of your web browser.
          </p>
        </div>

        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Third-party sites
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            If you click on one of the links to third-party websites, you may be
            taken to websites we do not control. This policy does not apply to
            the privacy practices of those websites. Read the privacy policy of
            other websites carefully. We are not responsible for these
            third-party sites.
          </p>
        </div>
        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          {" "}
          Children's Privacy
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            Our Service is not designed for use by anyone under the age of 16
            ("Children").
          </p>
          <p className="mb-4">
            We do not knowingly collect personally identifiable information from
            children under 16. If you are a parent or guardian and you learn
            that your Children have provided us with Personal Information,
            please contact us. If we become aware that we have collected
            Personal Information from children under age 16 without verification
            of parental consent, we take steps to remove that information.
          </p>
        </div>
        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Updates to this policy
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            This Privacy Policy was last updated on 28.07.2023. We reserve the
            right to update or change our Privacy Policy at any time, and you
            should check this Privacy Policy periodically. Your continued use of
            the Service after we post any modifications to the Privacy Policy on
            this page will constitute your acknowledgement of the modifications
            and your consent to abide and be bound by the modified Privacy
            Policy.
          </p>
        </div>
        <h2 className="text-xl 2xl:text-2xl tracking-wider mt-6 mb-4">
          Jurisdiction
        </h2>
        <div className="tracking-wider text-sm xl:text-base 2xl:text-lg text-gray-600">
          <p className="mb-4">
            If you choose to visit the website, your visit and any dispute over
            privacy are subject to this Policy and the website's terms of use.
            In addition to the foregoing, any disputes arising under this Policy
            shall be governed by the laws of India.
          </p>
          <p>
            If you have any questions about this Policy or other privacy
            concerns, you can also email us at contact@enjoyalgorithms.com.
          </p>
        </div>
        <div className="mt-6">
          <Footer />
        </div>
      </div>
    </div>
  )
}
